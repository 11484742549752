import { getCompany } from '@/actions/auth';
import { getCompanyGroupConfig } from '@/helpers/companyGroups';
import { Feature, getWordDefinition, GroupWordsDefinitions, isFeatureEnabled } from '@/helpers/featureFlagHelpers';
import _ from 'lodash';

export const generateDefaultContactFields = () => {
    const defaultFields = [
        {
            index: 0,
            title: 'Nombre',
            placeholder: 'Nombre',
            reference: 'firstName',
            isRequired: true,
        },
        {
            index: 1,
            title: 'Apellido',
            placeholder: 'Apellido',
            reference: 'lastName',
            hidden: isFeatureEnabled(Feature.DISABLE_LASTNAME)
        },
        {
            index: 2,
            title: 'Teléfonos',
            placeholder: 'Teléfono',
            reference: 'phones',
            isRequired: true
        },
        {
            index: 3,
            title: 'Emails',
            placeholder: 'Email',
            reference: 'emails'
        },
        {
            index: 4,
            title: "Documento de identidad",
            placeholder: 'Documento de identidad',
            reference: 'govId',
            hidden: !isFeatureEnabled(Feature.DISABLE_LASTNAME) || isFeatureEnabled(Feature.DISABLE_GOV_ID)
        },
        {
            index: 5,
            title: "Etiqueta",
            placeholder: 'Seleccionar',
            reference: 'statusTag',

        },
        {
            index: 6,
            title: "Dirección",
            placeholder: 'Selecciona una dirección...',
            reference: 'contactLocation',
            isRequired: isFeatureEnabled(Feature.LOCATION_REQUIRED),
            hidden: !isFeatureEnabled(Feature.LOCATION_FIELD)
        },
        {
            index: 7,
            title: getWordDefinition(GroupWordsDefinitions.SINGULAR_ORIGINS) || 'Orígen',
            placeholder: 'Seleccionar',
            reference: 'origin',
            isRequired: true
        },
        {
            index: 8,
            title: getCompanyGroupConfig().title.singular,
            placeholder: 'Seleccionar',
            reference: 'affiliatedCompanyGroup',
            hidden: !isFeatureEnabled(Feature.ENABLE_BUSINESS_SALES_MODEL)
        },
        {
            index: 9,
            title: "Comentario",
            placeholder: 'Comentario',
            reference: 'detail'
        }
    ]

    const generateFields = () => {

        const fields = defaultFields.map((field) => {
            const clonedField = { ...field };

            if (clonedField.reference === 'affiliatedCompanyGroup') {
                clonedField.hidden = !isFeatureEnabled(Feature.ENABLE_BUSINESS_SALES_MODEL);
            }

            return clonedField;
        });

        const additionalDataFields = _.get(getCompany(), 'additionalDataFields', [])
            .filter((field) => field.active)
            .map(({ name, code, required }, inx) => (
                {
                    index: (defaultFields.length) + inx,
                    title: name,
                    placeholder: name,
                    isRequired: required,
                    reference: `additionalData.${code}`,
                    active: required ? true : false
                }
            ));

        additionalDataFields.forEach((field) => {
            if (field?.position == 'top') {
                fields.unshift(field)
            } else {
                fields.push(field)
            }
        });

        return fields
    }

    return {
        _id: 'default',
        title: 'Por defecto',
        description: 'Formulario por defecto de Ventia',
        fields: [...generateFields()].filter((field) => !('hidden' in field) || !field.hidden)
    }
}

export const defaultFormContactFields = generateDefaultContactFields()
export const requiredFileds = defaultFormContactFields.fields.reduce((p, c) => ({ ...p, [c.reference]: c.isRequired ? 1 : 0 }), {})