'use client';

import { isAuth } from "@/actions/auth";
import ToastNotificationCard from "@/components/notification/ToastNotificationCard";
import { toast } from "@/components/ui/use-toast";
import useAwsSocket, { playNotificationSound } from "@/hooks/socket/useAwsSocket";
import _ from "lodash";
import { AudioLines, File, Image, Mail, MessageSquareText, SquarePlay, Sticker } from "lucide-react";
import { useRouter } from "next/router";
import { createContext, PropsWithChildren, ReactNode, useContext, useRef } from "react";

export type SocketStatus = 'offline' | 'online' | 'error';

type DefaultEventFields = {
    payload: any,
    room?: string
}
type CrmEvent = ({
    eventType: 'crmEvent',
    payload: {
        type: 'new' | 'mod' | 'remove',
        in: 'interaction' | 'contact',
        contact?: string,
        contactSummary: {
            firstName: string,
            lastName: string,
        },
        content: any,
        options?: Partial<{
            massive: boolean
        }>
    }
}) & Omit<DefaultEventFields, 'payload'>

type RoomEvent = ({
    eventType: 'roomEvent',
}) & DefaultEventFields

type CallEvent = ({
    eventType: 'callEvent',
}) & DefaultEventFields

export type SocketMessage = CallEvent | RoomEvent | CrmEvent;

type MediaType = 'image' | 'video' | 'audio' | 'file' | 'sticker' | 'text'

interface CreateToastNotificationParams {
    title?: string,
    body?: string,
    icon?: ReactNode,
    setting?: Partial<{
        playSound: boolean,
        duration: number
    }>
    onClick?: () => void
}

type PushRouter = (pathname: string, query?: Record<string, string>) => void

const SocketContext = createContext<{
    socket: WebSocket | null,
    status: SocketStatus,
    joinRoom: (room: string) => void,
    leaveRoom: (room: string) => void,
    addReceiveEventFn: (id: string, fn: (message: SocketMessage) => void) => void,
    removeReceiveEventFn: (id: string) => void
}>({
    status: 'offline',
    joinRoom() { },
    leaveRoom() { },
    socket: null,
    addReceiveEventFn() { },
    removeReceiveEventFn() { }
});

export function SocketContextProvider({ children }: PropsWithChildren) {
    const eventHandlers = useRef<Map<string, (message: SocketMessage) => void>>(new Map());

    const router = useRouter();

    const pushRouter: PushRouter = (pathname, query) => {
        const lastQuery = router.query || {};
        router.push({ pathname, query: { ...lastQuery, ...query } });
    }

    const removeReceiveEventFn = (id: string) => {
        eventHandlers.current.delete(id);
    };

    const addReceiveEventFn = (id: string, fn: (message: SocketMessage) => void) => {
        if (eventHandlers.current.has(id)) removeReceiveEventFn(id);
        eventHandlers.current.set(id, fn);
    };

    const { status, joinRoom, leaveRoom, socket } = useAwsSocket({
        onReceiveEvent(message) {
            handleEventNotification(message, pushRouter);
            eventHandlers.current.forEach((fn) => fn(message));
        }
    });

    return (
        <SocketContext.Provider value={{
            socket,
            status,
            joinRoom,
            leaveRoom,
            addReceiveEventFn,
            removeReceiveEventFn
        }}>
            {children}
        </SocketContext.Provider>
    );
}

const handleEventNotification = (event: SocketMessage, pushRouter: PushRouter) => {
    switch (event.eventType) {
        case 'crmEvent': {
            handleCrmEventNotifications(event, pushRouter);
            break;
        }
    }
}

const handleCrmEventNotifications = ({ payload }: CrmEvent, pushRouter: PushRouter) => {
    if (payload.in !== 'interaction' ||
        payload.type !== 'new' ||
        payload?.content?.content?.origin !== 'contact') return;

    const { firstName, lastName } = payload.contactSummary || {};

    const fullName = [firstName, lastName].filter(Boolean).join(' ') || 'Un contacto';

    let message: string = '';
    const interaction = payload?.content || {};
    let type: MediaType | 'email' = 'text';

    switch (interaction?.type) {
        case 'unofficial-whatsapp':
        case 'channelMessage': {

            if (interaction.type == 'channelMessage') {
                message = interaction?.content?.message?.text || ''
            } else {
                message = interaction?.content?.message || '';
            }

            if (!message) {
                const mediaType = interaction?.content?.message?.type || interaction?.content?.mediaType || 'text';
                type = mediaType;
                message = getTextByMediaType(mediaType);
            }
            break;
        }
        case 'email': {
            type = "email";
            message = 'Envió un email';
            break;
        }
        case 'smsMessage': {
            message = 'Envió un mensaje de texto'
            break;
        }
    }

    createToastNotification({
        icon: getNotificationIcon(type),
        title: fullName,
        body: message,
        onClick: () => {
            pushRouter('/contact', { id: interaction.contact })
        }
    });
}

const getTextByMediaType = (type: MediaType): string => {
    const mediaTypeMap: Record<MediaType, string> = {
        image: 'imagen',
        video: 'video',
        audio: 'audio',
        file: 'documento',
        sticker: 'sticker',
        text: 'texto',
    };

    return `Envió un ${mediaTypeMap[type]}`;
}

const getNotificationIcon = (type: MediaType | 'email') => {
    const mediaTypeMap: Record<(MediaType | 'email'), ReactNode> = {
        image: <Image size={21} />,
        video: <SquarePlay size={21} />,
        audio: <AudioLines size={21} />,
        file: <File size={21} />,
        sticker: <Sticker size={21} />,
        text: <MessageSquareText size={21} />,
        email: <Mail size={21} />
    };

    return mediaTypeMap[type];
}

export const createToastNotification = ({
    setting,
    title,
    body,
    icon,
    onClick,
}: CreateToastNotificationParams) => {
    const { playSound = true, duration = 3000 } = setting || {};

    const user = isAuth();

    const notificationSetting = _.get(user, 'config.notifications', {
        sound: 'default',
        enable: true,
        volume: 1
    });

    if (!notificationSetting.enable) return;

    if (playSound) {
        playNotificationSound(notificationSetting.sound, { volume: notificationSetting.volume });
    }

    const handleClick = () => {
        if (onClick) onClick();
    };

    toast({
        title,
        onClick: handleClick,
        duration,
        description: body,
        className: `p-0 ${!!onClick ? 'cursor-pointer' : ''}`,
        customElement: (
            <div style={{ transition: 'transform 150ms ease-out' }}>
                <ToastNotificationCard duration={duration} body={body} icon={icon} title={title} />
            </div>
        )
    });
};

export function useSocketContext() {
    return useContext(SocketContext);
};

export default SocketContext;
