import { createToastNotification } from '@/components/crm/socket/SocketContext';
import { messaging } from '@/lib/firebase/firebase';
import { MessagePayload, getToken, onMessage } from "firebase/messaging";
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const registerServiceWorkers = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const workers = ["/firebase-messaging-sw.js", "/service-worker.js"];
            await Promise.all(workers.map(async (worker) => {
                try {
                    await navigator.serviceWorker.register(worker);
                } catch (err) {
                    console.error(err, "Error al registrar el Service Worker");
                }
            }));
        } catch (error) {
            console.error("Error al registrar los Service Workers:", error);
        }
    }
};

export default function useHandlerNotification({ listenChanges = true }: { listenChanges: boolean } = { listenChanges: true }) {
    const [token, setToken] = useState<null | string>(null)

    const listenContact = useSelector((state: any) => state?.crm?.listenContact)

    const router = useRouter();

    const pushRouter = (pathname: string, query?: Record<string, string>) => {
        const lastQuery = router.query || {};
        router.push({ pathname, query: { ...lastQuery, ...query } });
    }

    useEffect(() => {
        if (messaging && listenChanges) {
            onMessage && onMessage(messaging, message => {
                handleNotification(message)
            })
        }
    }, [messaging, listenChanges, listenContact])

    const handleGenerateTokenFCM = async () => new Promise(async (resolve) => {
        try {
            if (!messaging) return resolve(null);
            if (!('Notification' in window)) return resolve(null);

            await Notification.requestPermission();
            if (Notification.permission !== 'granted') return resolve(null);

            const limit: number = 3;
            let increment: number = 0
            let fcmToken: null | string = null

            while (increment <= limit) {
                if (fcmToken) break;
                try {
                    const generatedToken = await getToken(messaging);
                    fcmToken = generatedToken;
                } catch (error) {
                    console.error('Error al generar el token FCM:', error);
                }

                increment++;
            }

            if (!fcmToken) return resolve(null);
            localStorage.setItem('fcm', fcmToken);
            setToken(fcmToken);
            return resolve(fcmToken);
        } catch (error) {
            console.error('Error al generar el token FCM:', error);
            return resolve(null)
        }
    })

    const handleNotification = (message: MessagePayload) => {
        const { notification, data = {} } = message || {};
        const { body, title } = notification || {};

        const contextType = data?.contextType;

        if (data?.mobile == "true" || contextType == 'new-message') return;

        const contactId = data?.contactId;
        let urlToRedirect = data?.url || data?.redirectUri || '';

        const withActionClick = urlToRedirect || contactId;

        createToastNotification({
            body,
            title,
            onClick: withActionClick ? (() => {
                if (contactId) {
                    pushRouter('/contact', { id: contactId });
                } else if (urlToRedirect) {
                    pushRouter(urlToRedirect);
                }
            }) : undefined
        });
    }

    return { handleGenerateTokenFCM, token }
}

