'use client';
import { isGhostAdmin } from "@/actions/auth";
import { saveFcmToken } from "@/actions/user";
import { messaging } from "@/lib/firebase/firebase";
import { getToken } from "firebase/messaging";
import cookie from "js-cookie";
import { useEffect } from "react";

export default function useNotificationPermission() {
    const session = cookie.get("token")

    const requestNotificationPermission = async (): Promise<NotificationPermission> => {
        let notificationTimeout: NodeJS.Timeout | null = null;

        return new Promise((resolve) => {
            Notification.requestPermission().then((permission) => {
                if (notificationTimeout) {
                    clearTimeout(notificationTimeout);
                    notificationTimeout = null;
                };

                resolve(permission);
            });
        });
    };

    const onSaveFcmToken = async (token: string) => {
        try {
            await saveFcmToken(token);
        } catch (error) {
            console.error(error)
        }
    }

    const setNotificationsPermissionRequested = () => {
        localStorage.setItem('notificationsPermissionRequested', '1');
    }

    const validateNotificationPermission = async () => {
        if (!session || !messaging || isGhostAdmin()) return;

        const permissionState = Notification.permission;

        const existFcmToken = localStorage.getItem('fcm');
        let notificationsPermissionRequested = localStorage.getItem('notificationsPermissionRequested');
        const lastPermissionState = localStorage.getItem('lastPermissionState');

        if (notificationsPermissionRequested && lastPermissionState !== permissionState) {
            notificationsPermissionRequested = null;
            localStorage.removeItem('notificationsPermissionRequested');
        }

        if (existFcmToken || permissionState == 'denied' || !!notificationsPermissionRequested) {
            setNotificationsPermissionRequested()
            return;
        }

        let isGrantedPermission = permissionState == 'granted';

        if (!isGrantedPermission) {
            const result = await requestNotificationPermission();
            isGrantedPermission = result == 'granted';
        }

        if (lastPermissionState !== Notification.permission) {
            localStorage.setItem('lastPermissionState', Notification.permission);
        };

        setNotificationsPermissionRequested();

        if (!isGrantedPermission) return;
        const fcmToken = await getToken(messaging);
        if (!fcmToken) return;

        localStorage.setItem('fcm', fcmToken);
        await onSaveFcmToken(fcmToken);
    };

    useEffect(() => {
        validateNotificationPermission();
    }, [!!messaging, session])

    return {}
}
